import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  Button,
  ImagePreview,
  ScanCodeConsultButton,
  TabSwitcher,
  PageBottomCard,
  TestimonyCard,
  MoreResources,
} from 'components/index'
import Section from 'components/SolutionComponents/Section'
import { ButtonGroup } from 'components/Base/Button'
import SigningPlate from 'components/Solutions/SigningPlate'
import NewShowCard from 'components/engines/NewShowCard'
import SceneCard from 'components/engines/SceneCard'
import Carousel from 'components/Carousel'
import SectionCard from 'components/engines/SectionCard'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'

const CDP = (props) => {
  const isMb = useIsWindowWidthSmaller()
  const { data } = props
  const { cdpYaml } = data
  const {
    title,
    description,
    keywords,
    banner,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    section8,
  } = cdpYaml

  return (
    <Layout {...props}>
      <Seo title={title} description={description} keywords={keywords} />
      <main className={styles.container}>
        <div className={styles.bannerWrapper}>
          <Banner
            {...banner}
            h1tag={true}
            className={classnames(styles.banner)}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            enTitleClassName={styles.enTitle}
          />
        </div>
        <Section
          className="lg:!mt-0 lg:pt-[100px]  pb-0 bg-[#f9fafc] lg:bg-[#fff]"
          title={section1?.title}
          desc={section1?.desc}
          descClassName={styles.sectionDesc}
          titleClassName={styles.sectionTitle}
        >
          <div className="lg:flex lg:justify-center md:mt-[73px] mt-[2.6rem] md:px-0 px-[2rem]">
            {section1?.stepArr?.map((item) => {
              return <NewShowCard className="lg:mx-[13px] mb-[10px] lg:mb-0" key={item?.title} data={item} />
            })}
          </div>
          <div className="flex justify-center mt-[30px] lg:mt-[60px]">
            <Button btnType="primary" href={section1?.button?.href} target="_blank">
              {section1?.button?.text}
            </Button>
          </div>
          {/* 神策数据获评 IDC MarketScape 中国客户数据平台（CDP）领导者类别 */}
          <div className={classnames('lg:mt-[100px] pb-[30px] px-[2rem] lg:px-0 mt-[60px]', styles.sectionCard)}>
            <SectionCard dataSource={section8} />
          </div>
        </Section>
        <Section
          className="lg:!mt-0 lg:pt-[100px] lg:pb-[100px] bg-[#F9FAFC] lg:bg-[#fff]"
          title={section2?.title}
          desc={section2?.desc}
          descClassName={styles.sectionDesc}
        >
          <div className="flex justify-center md:mt-[60px] mt-[2rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview imageUrl={section2?.img_url?.publicURL || section2?.img_url} alt="客户数据引擎应用场景" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            <Button href={section2?.button?.left_btn_href} target="_blank" btnType="primary">
              {section2?.button?.left_btn_text}
            </Button>
            <ScanCodeConsultButton
              className="lg:mx-[20px] ml-[16px] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode={section2?.button?.right_btn_href}
              size="small"
              target="_blank"
              buttonText={section2?.button?.right_btn_text}
              imgBottomText="微信扫码 立即咨询"
              ghost
              buttonType="primary"
            />
          </ButtonGroup>
        </Section>
        <Section
          className="lg:!mt-0 lg:pt-[70px] lg:pb-[70px] pb-0 bg-[#F9FAFC] "
          title={section3.title}
          desc={section3?.desc}
          descClassName={styles.sectionDesc}
        >
          <div className={styles.partContent}>
            <TabSwitcher
              data={section3?.dataSource}
              longTitle
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
            />
          </div>
        </Section>
        {/* 客户数据引擎的应用场景 */}
        <Section
          className="lg:!mt-0 lg:!py-[70px] bg-[#F9FAFC] lg:bg-[#fff] "
          title={section4?.title}
          desc={section4?.desc}
          descClassName="lg:mt-0"
        >
          <div className="mx-[20px] pt-[26px] lg:flex lg:pt-[60px] justify-center flex-wrap lg:mx-auto lg:w-[1200px]">
            {section4?.dataSource?.map((item) => {
              return <SceneCard className="lg:mb-[26px] lg:mx-[13px] mb-[10px]" key={item?.title} data={item} />
            })}
          </div>
          <ButtonGroup className={classnames('md:mt-[34px] mt-[3rem] flex justify-center')}>
            <Button href={section4?.buttons?.left_btn_href} target="_blank" btnType="primary">
              {section4?.buttons?.left_btn_text}
            </Button>
            <ScanCodeConsultButton
              className="lg:mx-[20px] ml-[16px] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode={section4?.buttons?.right_btn_href}
              size="small"
              target="_blank"
              buttonText={section4?.buttons?.right_btn_text}
              imgBottomText="微信扫码 立即咨询"
              ghost
              buttonType="primary"
            />
          </ButtonGroup>
        </Section>
        {/* 神策 CDP 赋能客户全生命周期运营 */}
        <section className={classnames(styles.section, 'bg-[#F9FAFC]')}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...section6} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <Carousel
              img={section6?.icons?.publicURL || section6?.icons}
              className={styles.carousel}
              showMask
              iconContain
            />
          </div>
        </section>
        {/* 更多干货 */}
        <Section
          className="lg:!mt-0 lg:pt-[100px] lg:pb-[100px] !pt-[5rem] pb-[0] lg:bg-[#fff] bg-[#F9FAFC]"
          title={section7?.title}
        >
          <div className="flex justify-center md:mt-[60px] mt-[2rem] pl-[20px] pr-[20px]">
            <MoreResources data={section7?.dataSource} />
          </div>
        </Section>
        <Section className="lg:!mt-0 lg:pt-[70px] lg:pb-[10px] pb-[3rem] bg-[#F9FAFC] " title={section5?.title}>
          <SigningPlate
            className="lg:block "
            dataSource={section5}
            linkIconClassName={styles.linkIconClassName}
            enTitle={section5?.enTitle}
          />
        </Section>
        <PageBottomCard
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
          type="newBg"
        />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    cdpYaml {
      title
      description
      keywords
      banner {
        title
        enTitle
        desc
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      section1 {
        title
        desc
        stepArr {
          title
          desc
          img {
            publicURL
          }
        }
        button {
          text
          href
        }
      }
      section2 {
        title
        desc
        img_url {
          publicURL
        }
        button {
          left_btn_text
          left_btn_href
          right_btn_text
          right_btn_href
        }
      }
      section3 {
        title
        desc
        dataSource {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
            }
            introductionList {
              content
            }
          }
        }
      }
      section4 {
        title
        desc
        dataSource {
          title
          desc
          img {
            publicURL
          }
        }
        buttons {
          left_btn_text
          left_btn_href
          right_btn_text
          right_btn_href
        }
      }
      section5 {
        title
        left {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        right {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        enTitle
      }
      section6 {
        title
        text
        signature
        imgUrl {
          publicURL
        }
        imgAlt
        icons {
          publicURL
        }
      }
      section7 {
        title
        dataSource {
          img {
            publicURL
          }
          title
          tip
          url
          text
        }
      }
      section8 {
        left {
          img {
            publicURL
          }
        }
        right {
          title
          desc
          button {
            text
            href
            target
            btnType
          }
        }
      }
    }
  }
`

export default CDP
